body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1920 1080'%3E%3CradialGradient id='SVGID_1_' cx='1465.15' cy='268.7' r='575.6' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ebbb4e' stop-opacity='.9'/%3E%3Cstop offset='1' stop-color='%23ebbb4e' stop-opacity='0'/%3E%3C/radialGradient%3E%3Ccircle cx='1465.15' cy='268.7' r='575.6' fill='url(%23SVGID_1_)'/%3E%3CradialGradient id='SVGID_2_' cx='1002.24' cy='503.54' r='575.6' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%235b4ccc' stop-opacity='.7'/%3E%3Cstop offset='1' stop-color='%234d419d' stop-opacity='0'/%3E%3C/radialGradient%3E%3Ccircle cx='1002.24' cy='503.54' r='575.6' fill='url(%23SVGID_2_)'/%3E%3C/svg%3E")
    no-repeat #3f1919 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDialog-paperScrollPaper {
  background: linear-gradient(
    90deg,
    rgba(34, 60, 61, 1) 8%,
    rgba(38, 51, 83, 1) 48%,
    rgba(35, 37, 46, 1) 100%
  );
  boxshadow: -2px 3px 13px -1px rgba(0, 0, 0, 0.8);

  width: auto;
  height: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px !important;
  padding: 4px;
  z-index: 10000000 !important;
}
.dialoge__content__section {
  background: linear-gradient(
    90deg,
    rgba(34, 60, 61, 1) 8%,
    rgba(38, 51, 83, 1) 48%,
    rgba(35, 37, 46, 1) 100%
  );
  border-radius: 30px;
  width: 100%;
  height: 100%;
  z-index: 10000000 !important;
}

@media screen and (max-width: 544px) {
  .MuiDialog-paperScrollPaper {
    width: 100%;
  }
}

/* Custom CSS */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 1200px) {
  #header_link {
    transform: translateX(-230px) !important;
  }
  #header_button {
    top: 36px !important;
    transform: translateX(24px) !important;
  }
}

/* .jss12,
.jss13 {
  padding-top: 8px !important;
} */

/* .jss7 header {
  background: none !important;
  box-shadow: none !important;
  position: static !important;
  padding: 16px 0 !important;
} */

/* .jss12 .MuiButton-contained,
.jss28 .MuiGrid-item:nth-of-type(7) .MuiButton-contained,
.jss47 .MuiGrid-item:nth-of-type(7) .MuiButton-contained,
.jss66 .MuiGrid-item:nth-of-type(7) .MuiButton-contained {
  background: radial-gradient(
    ellipse at bottom,
    rgba(85, 180, 200, 1),
    rgba(85, 180, 200, 0)
  ) !important;
  color: #23ead3 !important;
  letter-spacing: normal !important;
  box-shadow: 1px 1px 0px #43727a inset, 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
} */

/* .jss15 .MuiButton-contained,
.jss28 .MuiGrid-item:nth-of-type(8) .MuiButton-contained,
.jss47 .MuiGrid-item:nth-of-type(8) .MuiButton-contained,
.jss66 .MuiGrid-item:nth-of-type(8) .MuiButton-contained {
  background: radial-gradient(
    ellipse at bottom,
    rgba(56, 114, 255, 1),
    rgba(56, 114, 255, 0)
  ) !important;
  color: #84b7ff !important;
  letter-spacing: normal !important;
  box-shadow: 1px 1px 0px #3872ff inset, 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
} */
/* .jss28 .MuiGrid-item:nth-of-type(9) .MuiButton-contained,
.jss47 .MuiGrid-item:nth-of-type(9) .MuiButton-contained,
.jss66 .MuiGrid-item:nth-of-type(9) .MuiButton-contained {
  background: radial-gradient(
    ellipse at bottom,
    rgba(213, 179, 107, 1),
    rgba(213, 179, 107, 0)
  ) !important;
  color: #ffe8b6 !important;
  letter-spacing: normal !important;
  box-shadow: 1px 1px 0px #a58543 inset, 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
} */
/* .jss17,
.jss28,
.jss47,
.jss66 {
  box-shadow: none !important;
  background: rgba(255, 255, 255, 0.05) !important;
} */
/* .jss16 > .MuiGrid-container {
  background: rgba(20, 23, 42, 0.3) !important;
  border-radius: 72px 72px 52px 52px !important;
} */
/* .MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 16px !important;
}
.jss85 img {
  width: 120px !important;
  top: 50% !important;
  transform: translate(-10%, -50%) !important;
} */
/* .jss85 span span {
  display: none !important;
}
.jss86 + div {
  background-color: #7a7d9f !important;
}
.MuiAccordion-root {
  padding: 8px 16px !important;
}
.jss88 {
  font-weight: bold !important;
  font-size: 1.2rem !important;
}
.jss91 {
  background: none !important;
  border-top: solid 1px rgba(255, 255, 255, 0.2) !important;
  padding-bottom: 48px !important;
  max-width: 1280px !important;
  margin: 0 auto;
}
.jss91 div[align="right"] {
  display: none;
}
.jss91 div[align="left"] {
  text-align: center;
  margin: 0 auto;
}
.jss93 img {
  width: 200px !important;
}

.jss94,
.jss96 {
  padding: 16px 0;
}

.jss95 svg {
  margin: 0px 8px;
} */
